@use "../styles/theme.scss";

:local(.faq) {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media (min-width: theme.$breakpoint-lg) {
    padding: 2rem;
  }
}

:local(.faq-container) {
  max-width: 1000px;
  text-align: center;
  width: 100%;
}

:local(.faq-title) {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;

  @media (min-width: theme.$breakpoint-lg) {
    font-size: 1.75rem;
    line-height: 2.75rem;
  }

  @media (min-width: theme.$breakpoint-xl) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media (min-width: theme.$breakpoint-xll) {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
}

:local(.faq-content) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 40px;
}

:local(.faq-item) {
  width: 100%;
  text-align: left;
}

:local(.faq-question) {
  font-weight: 500;
  font-size: 1.125rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem 1rem 1rem 1.5rem;
  background-color: var(--mic-setup-modal-bg-color);
  align-items: center;

  @media (min-width: theme.$breakpoint-lg) {
    padding: 1.5rem 1.5rem 1.5rem 2rem;
  }

  h3 {
    font-weight: 500;
    margin-right: 1rem;
  }
}

:local(.faq-answer) {
  display: none;
  padding: 1rem 1rem 1rem 1.5rem;
  line-height: 1.5rem;

  @media (min-width: theme.$breakpoint-lg) {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
}

:local(.opened) {
  display: block;
}
