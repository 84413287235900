@use "../../styles/theme.scss";

:local(.form) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  @media (min-width: theme.$breakpoint-xl) {
    flex-direction: row;
    justify-content: center;
    height: 600px;
    padding: 2rem;
  }

  @media (min-width: theme.$breakpoint-xll) {
    gap: 140px;
  }
}

:local(.form-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (min-width: theme.$breakpoint-xl) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 70px;
  }
}

:local(.title) {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  max-width: 760px;

  @media (min-width: theme.$breakpoint-lg) {
    font-size: 1.75rem;
    line-height: 2.75rem;
  }

  @media (min-width: theme.$breakpoint-xl) {
    font-size: 2rem;
    line-height: 3rem;
    max-width: 500px;
    text-align: left;
  }

  @media (min-width: theme.$breakpoint-xll) {
    font-size: 2.5rem;
    line-height: 3.75rem;
    max-width: 740px;
  }
}

:local(.input-group) {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (min-width: theme.$breakpoint-lg) {
    width: 500px;
  }
}

:local(.submit-area) {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    height: 50px;
  }

  @media (min-width: theme.$breakpoint-xl) {
    justify-content: flex-start;
  }
}

:local(.form-success) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  max-width: unset !important;
}
