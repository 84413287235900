@use "../styles/theme.scss";
:local(.avatar) {
    background-color: theme.$accent1-color-hover;
    border: 0;
    color: #fff;
    font-size: 18px;
    border-radius: 50%;
    text-align: center;
    margin: 0 8px;
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

:local(.dropdown) {
    position: relative;
    display: inline-block;
}

:local(.link-item) {
    color: theme.$primary-color;
    line-height: 35px;
    letter-spacing: 0;
    font-weight: 300;
    overflow-wrap: break-word;
}

:local(.linkSubItem) {
    font-size: 18px;
    color: theme.$text1-color;
    font-weight: 300;
    cursor: pointer;
    overflow-wrap: break-word;
}

:local(.link-icon) {
    margin-right: 10px;
    height: 24px;
    width: 24px;
}

:local(.link-item-email) {
    line-height: 35px;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 270px;
    white-space: nowrap;
}

:local(.section-title) {
    font-size: 23px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0;
    text-decoration: none;
    color: theme.$text1-color;
    &:hover {
        color: theme.$text1-color;
    }
}

:local(.dropdown-content) {
    position: absolute;
    background-color: theme.$background1-color;
    color: theme.$text1-color;
    right: 0;
    opacity: 0;
    width: 380px;
    margin-top: 10px;
    transition: 0.3s;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 45px;
    z-index: 1;
    li {
        list-style: none;
    }
    &:hover {
        display: block;
    }
}