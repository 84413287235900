@use "../styles/theme.scss";
:local(.home-page) {
    flex: 1;
    background-size: cover;
    padding: 0;
    flex-direction: column;
    align-items: center;
    @media (min-width: theme.$breakpoint-lg) {
        align-items: center;
        justify-content: flex-start;
    }
    &>section {
        margin: 2em auto;
    }
    * {
        scrollbar-width: auto;
        scrollbar-color: theme.$primary-color #e7e7e7;
    }
    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 9px;
    }
    *::-webkit-scrollbar-track {
        background: #e7e7e7;
    }
    *::-webkit-scrollbar-thumb {
        background-color: theme.$primary-color;
        border-radius: 2px;
        border: 0px none #ffffff;
    }
}

:local(.logo-container) {
    display: none;
    justify-content: center;
    padding: 20px;
    width: 150px;
    img {
        width: 100%;
        align-self: flex-start;
    }
    @media (min-width: theme.$breakpoint-lg) {
        display: none;
    }
}

:local(.sign-in-container) {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-right: 21px;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-left: 3.5rem;
    @media (min-width: theme.$breakpoint-md) {
        margin-top: 0;
    }
    @media (min-width: theme.$breakpoint-lg) {
        display: none;
    }
}

:local(.mobile-sign-out) {
    padding: 0.5rem;
}

:local(.hero) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /** Custom styles */
    position: relative;
    height: 100vh;
    min-height: 600px;
    @media (min-width: theme.$breakpoint-lg) {
        flex-direction: row;
        margin-top: 0;
    }
}

:local(.hero-image-container) {
    img,
    video {
        position: absolute;
        top: 50px;
        left: 0;
        object-fit: cover;
        height: calc(100% - 2rem - 40px);
        width: 100%;
        z-index: -1;
        @media (min-width: theme.$breakpoint-lg) {
            border-radius: 16px;
            /** Custom styles */
            border-radius: 0;
            height: 100%;
            top: 0;
        }
    }
}

:local(.app-info) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* Custom styles */
    background-color: theme.$background1-color;
    border-radius: 10px;
    margin: auto;
    padding: 30px 12.5px 10px 12.5px;
    width: 315px;
    &>* {
        margin-bottom: 20px;
    }
    // @media (min-height: 600px) {
    //   margin-top: auto;
    //   margin-bottom: auto;
    // }
    /* --- Custom styles --- */
    @media (min-width: theme.$breakpoint-md) {
        width: 533px;
        padding: 55px 55px 35px 55px;
    }
    /* --- End Custom styles --- */
    @media (min-width: theme.$breakpoint-lg) {
        justify-content: flex-start;
        align-items: flex-start;
        /** Custom styles */
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        &> :last-child {
            margin-bottom: 0;
        }
    }
    @media (min-width: theme.$breakpoint-xl) {
        align-items: flex-start;
        margin-right: 140px;
        padding: 45px;
        width: 513px;
    }
    @media (min-width: theme.$breakpoint-xll) {
        margin-right: 150px;
        width: 533px;
        padding: 55px;
    }
    @media (min-width: theme.$breakpoint-xxl) {
        width: 612px;
        margin-right: 225px;
    }
}

:local(.app-description) {
    white-space: pre-wrap;
    align-self: auto;
    font-size: theme.$font-size-lg;
    font-weight: theme.$font-weight-bold;
    font-weight: theme.$font-weight-medium;
    text-align: center;
    margin: 0 24px 20px 24px;
    line-height: 1.25;
    /** Custom styles */
    margin: 0 0 15px 0;
    font-size: theme.$font-size-xl;
    font-weight: theme.$font-weight-bold;
    @media (min-width: theme.$breakpoint-md) {
        font-size: theme.$font-size-4xl;
    }
    @media (min-width: theme.$breakpoint-xl) {
        text-align: left;
        max-width: 860px;
        /** Custom styles */
        margin: 0 0 20px 0;
        font-size: theme.$font-size-3xl;
    }
    @media (min-width: theme.$breakpoint-xll) {
        font-size: theme.$font-size-4xl;
    }
}

:local(.center-logo) {
    align-self: auto;
    margin: 24px 0;
}

:local(.cta-buttons) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

:local(.features) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    &>* {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
        @media (min-width: theme.$breakpoint-lg) {
            margin-right: 24px;
            margin-bottom: 0;
            flex-direction: row;
            max-width: 364px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    img {
        width: 100%;
        border-radius: theme.$border-radius-small;
    }
}

:local(.rooms-container) {
    margin-top: 120px !important;
    display: flex;
    flex-direction: column;
    /** Custom styles */
    margin-top: 48px !important;
    @media (min-width: theme.$breakpoint-md) {
        margin-left: 48px;
        margin-right: 48px;
    }
    @media (min-width: theme.$breakpoint-xl) {
        margin-left: 100px;
        margin-right: 100px;
    }
}

:local(.rooms-heading) {
    margin-left: 1rem;
    font-size: 24px;
    margin-bottom: 16px;
    /** Custom styles */
    @media (min-width: theme.$breakpoint-xl) {
        font-size: theme.$font-size-3xl;
    }
}

:local(.rooms) {
    background-color: theme.$background2-color;
    border-radius: 16px;
    /** Custom styles */
    border-radius: 0;
    background-color: transparent;
}

:local(.row) {
    display: flex;
    justify-content: space-evenly !important;
}

:local(.col-lg) {
    flex-direction: column;
    @media (min-width: theme.$breakpoint-lg) {
        flex-direction: row;
    }
}

:local(.home-page) :local(.card) {
    background-color: theme.$background2-color;
    border-radius: 16px;
    font-weight: theme.$font-weight-medium;
    padding-bottom: 32px !important;
    flex: 1;
}

:local(.center-lg) {
    @media (max-width: theme.$breakpoint-lg) {
        text-align: center;
        align-items: center;
    }
}

:local(.app-sub-description) {
    margin-bottom: 30px;
    font-size: theme.$font-size-md;
    font-weight: 400;
    line-height: theme.$font-size-xl;
    text-align: center;
    @media (min-width: theme.$breakpoint-md) {
        margin-bottom: 40px;
    }
    @media (min-width: theme.$breakpoint-xl) {
        text-align: left;
    }
}