@use "../styles/theme.scss";

:local(.media-tile) {
  display: flex;
  flex-direction: column;
  border: none;
  height: max-content;
  text-decoration: none;
  background: transparent;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  .create-tile-borders {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    svg {
      height: 100%;
      width: 100%;
      rect {
        stroke: var(--primary-color) !important;
      }
    }
  }
}

.active {
  border: 1px solid var(--primary-color);
}

:local(.thumbnail-container) {
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: transparent;
  border-radius: theme.$border-radius-small;

  img,
  video,
  :local(.create-tile-content) {
    border-radius: theme.$border-radius-small;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

:local(.tall) {
  transition: 0.2s ease-in-out background-color;
  border-radius: 3px;

  &:hover {
    background-color: theme.$darkgreyLabel;

    button svg,
    b,
    button svg path {
      color: #fff !important;
      fill: #fff !important;
    }
  }
}

:local(.create-tile) {
  &:hover {
    background-color: var(--primary-color-minor-opacity, transparent);
  }

  &:active {
    background-color: var(--primary-color-minor-opacity, transparent);
  }
}

:local(.tall) :local(.thumbnail-container),
:local(.wide) :local(.thumbnail-container) {
  height: 0;

  img,
  video,
  :local(.create-tile-content) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

:local(.wide) :local(.thumbnail-container) {
  padding-top: 56.25%;
}

:local(.tall) :local(.thumbnail-container) {
  padding-top: 152.78%;
}

:local(.thumbnail-link) {
  display: flex;
  width: 100%;

  img,
  video {
    margin: auto;
  }
}

:local(.tile-actions) {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
}

:local(.tile-avatar-actions) {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    background: transparent;
    border: none;
  }
}

:local(.tile-actions) > * {
  margin-bottom: 4px;
}

:local(.tile-actions) > :last-child {
  margin-bottom: 0;
}

:local(.tile-action),
:local(.member-count) {
  background: theme.$tile-button-bg-color;
  color: theme.$tile-button-text-color;
  height: 30px;
  border-radius: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid theme.$tile-button-border-color;

  svg {
    color: theme.$tile-button-text-color;
  }
}

:local(.tile-action) {
  // &:hover {
  //   background-color: theme.$tile-button-bg-color-hover;
  //   color: white;
  // }

  // &:active {
  //   background-color: theme.$tile-button-bg-color-pressed;
  // }
}

:local(.member-count) {
  position: absolute;
  right: 8px;
  bottom: 8px;

  svg {
    margin-right: 4px;
  }
}

:local(.favorite-icon) {
  position: absolute;
  top: 8px;
  left: 8px;
  color: theme.$favorite-color;
}

:local(.info) {
  margin-top: 15px;
  color: theme.$text1-color;

  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    display: block;
    padding-bottom: 4px;
  }

  b {
    font-size: theme.$font-size-lg;
  }
}

:local(.description) {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  padding-bottom: 0;
  margin-top: 5px;
  font-size: theme.$font-size-md;
  line-height: 21px;
  overflow: hidden;

  & > * {
    margin-right: 4px;
  }

  & > :last-child {
    margin-right: 0;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  a svg {
    color: theme.$link-color;

    &:hover {
      color: theme.$link-color-hover;
    }

    &:active {
      color: theme.$link-color-pressed;
    }
  }
}

:local(.create-tile) {
  height: 100%;
  box-shadow: none;
}

:local(.create-tile-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: theme.$tile-text-color;
  font-weight: theme.$font-weight-bold;
  border-radius: theme.$border-radius-small;
  flex: 1;

  p {
    font-size: theme.$font-size-sm;
    color: theme.$primary-color !important;
  }

  svg {
    margin-bottom: 16px;
    color: theme.$primary-color !important;
  }
}

:local(.joined-at) {
  color: theme.$text1-color;
  margin-top: 0.25rem;
  font-size: 10px;
  font-weight: theme.$font-weight-bold;
}

:local(.collectionTile) {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }
}

:local(.tile-footer) {
  align-items: center;
}

:local(.loading-tile) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--primaryColorBackground);
  min-height: 350px;
}
