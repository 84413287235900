@use "../styles/theme.scss";

:local(.contact-me-area) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

:local(.contact-me-area-title) {
  font-weight: theme.$font-weight-semi-bold;
  font-size: theme.$font-size-xl;
  margin-bottom: 0.5rem;
}

:local(.contact-me-area-email) {
  color: theme.$primary-color;
  font-weight: theme.$font-weight-semi-bold;
  font-size: theme.$font-size-lg;
  text-decoration: underline;
}
