@use "../styles/theme.scss";

:local(.have-a-room-code-button) {
  display: none;
  color: theme.$text5-color;
  margin-left: 1rem;
  border-color: theme.$primary-color;
  background-color: theme.$primary-color;
  font-size: 14px;
  height: 52px;

  &:hover {
    background-color: theme.$primary-color-hover;
    color: theme.$text5-color;
  }

  &:active {
    background-color: theme.$primary-color-pressed;
    color: theme.$text5-color;
  }

  @media (min-width: theme.$breakpoint-md) {
    display: flex;
  }
}