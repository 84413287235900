@use "../styles/theme.scss";

:local(.sign-in-button) {
  display: none;
  color: theme.$text1-color;
  margin-left: 8px;

  @media (min-width: theme.$breakpoint-lg) {
    display: flex;
    font-size: 14px;
    height: 52px;
  }
}

:local(.mobile-sign-in) {
  display: flex;
  margin-top: 5px;
  font-size: 14px;

  @media (min-width: theme.$breakpoint-lg) {
    display: none;
    height: 52px;
    font-size: 14px;
  }
}
