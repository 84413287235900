@use "../styles/theme.scss";

:local(.highlightedRoom) {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin: 20px 16px;

  @media (min-width: theme.$breakpoint-md) {
    margin: 50px 60px;
    min-height: calc(100vh - 50px);
  }

  @media (min-width: theme.$breakpoint-xl) {
    margin: 70px 100px;
    min-height: calc(100vh - 70px);
  }

  @media (min-width: theme.$breakpoint-xxl) {
    margin: 60px 120px;
    min-height: calc(100vh - 60px);
  }

  &:nth-child(odd) :local(.room-content) {
    @media (min-width: theme.$breakpoint-xl) {
      margin-right: auto;
      margin-left: 80px;
    }

    @media (min-width: theme.$breakpoint-xll) {
      margin-left: 50px;
    }

    @media (min-width: theme.$breakpoint-xxl) {
      margin-left: 160px;
    }
  }
}

:local(.backgroundImage) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  z-index: -1;
  height: 100%;
}

:local(.room-content) {
  background-color: theme.$background1-color;
  border-radius: 10px;
  height: max-content;
  margin: 2rem 14px;
  padding: 30px;
  width: 100%;

  @media (min-width: theme.$breakpoint-md) {
    margin: 2rem auto;
    padding: 40px;
    width: 513px;
    padding: 60px 55px 55px;
  }

  @media (min-width: theme.$breakpoint-lg) {
    margin-bottom: 0;
    margin-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;

    /** Custom styles */
    margin-left: auto;
    margin-bottom: 2rem;
  }

  @media (min-width: theme.$breakpoint-xl) {
    width: 513px;
    padding: 45px;
    margin-right: 80px;
  }

  @media (min-width: theme.$breakpoint-xll) {
    width: 533px;
    padding: 55px;
    margin-right: 50px;
  }

  @media (min-width: theme.$breakpoint-xxl) {
    width: 612px;
    margin-right: 160px;
  }

  @media (min-height: theme.$breakpoint-md) {
    margin-top: auto;
    margin-bottom: auto;
  }
}

:local(.title) {
  font-weight: theme.$font-weight-bold;
  font-size: theme.$font-size-xl;
  text-align: center;
  line-height: 1.5;

  @media (min-width: theme.$breakpoint-md) {
    font-size: theme.$font-size-4xl;
  }

  @media (min-width: theme.$breakpoint-xl) {
    text-align: left;
    font-size: theme.$font-size-xl;
  }

  @media (min-width: theme.$breakpoint-xll) {
    font-size: theme.$font-size-4xl;
  }
}

:local(.description) {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-top: 15px;
  max-height: 300px;
  overflow: auto;
  text-align: center;

  @media (min-width: theme.$breakpoint-md) {
    max-height: 170px;
    margin-top: 20px;
    padding-right: 24px;
  }

  @media (min-width: theme.$breakpoint-xl) {
    text-align: left;
    max-height: 212px;
  }
}

:local(.button) {
  margin: auto;
  margin-top: 30px;

  @media (min-width: theme.$breakpoint-xl) {
    margin-top: 40px;
    margin-left: 0;
  }

  @media (min-width: theme.$breakpoint-xxl) {
    margin-top: 50px;
  }
}

:local(.artist) {
  font-weight: 900;
  margin-bottom: 15px;
  text-align: center;

  @media (min-width: theme.$breakpoint-xl) {
    text-align: left;
  }
}
