@use "../styles/theme.scss";

:local(.media-grid) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  place-items: start;
  width: 100%;
  align-content: flex-start;
  flex: 1;
  gap: 10px;

  & > * {
    width: 100%;
    padding: 8px;
  }

  @media (min-width: theme.$breakpoint-sm) {
    gap: 20px 15px;
  }
}

// :local(.sm) {
//   grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

//   @media (min-width: theme.$breakpoint-lg) {
//     grid-template-columns: repeat(auto-fill, minmax(150px, 156px));
//   }
// }

:local(.center) {
  justify-content: space-evenly;
}

:local(.variable-width) {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > * {
    min-width: 200px;
    min-height: 200px;
    max-height: 240px;
    max-width: 320px;
    width: auto;
  }
}
