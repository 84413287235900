@use "../styles/theme.scss";

:local(.radio-input) {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px 0;
}

:local(.option) {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 16px;
  cursor: pointer;
  user-select: none;
  text-align: left;
  flex-direction: row-reverse;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

:local(.input) {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 2px solid theme.$radio-border-color;
  background-color: theme.$radio-bg-color;
  align-self: center;
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:checked {
    border-color: theme.$active-color;
  }

  &::after {
    content: " ";
    position: absolute;
    background-color: transparent;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    width: 14px;
    height: 14px;
    border-radius: 14px;
  }

  &:hover::after {
    background-color: theme.$primary-color-hover;
  }

  &:active::after {
    background-color: theme.$primary-color-pressed;
  }

  &:checked::after {
    background-color: theme.$active-color;
  }

  &:checked:hover::after {
    background-color: theme.$active-color-hover;
  }

  &:checked:active::after {
    background-color: theme.$active-color-pressed;
  }
}

:local(.content) {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  color: theme.$text2-color;
}

:local(.label) {
  font-size: 18px;
  font-weight: theme.$font-weight-bold;
}

:local(.description) {
  margin-top: 4px;
  font-size: 16px;
  color: theme.$text4-color;
  font-weight: theme.$font-weight-regular;
}
