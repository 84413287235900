@use "../styles/theme.scss";
header {
  display: block;
  @media (min-width: theme.$breakpoint-lg) {
    padding: 0;
  }
  a {
    color: theme.$black;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
  }
  nav {
    display: flex;
    flex-direction: column;
    @media (min-width: theme.$breakpoint-lg) {
      flex-direction: row;
    }
    li {
      &:first-child {
        padding-top: 20px;
        /* Custom styles */
        padding-top: 0;
        @media (min-width: theme.$breakpoint-lg) {
          img {
            width: auto;
            height: auto;
            /* Custom styles */
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      padding-top: 20px;
      @media (min-width: theme.$breakpoint-lg) {
        padding-top: 0;
      }
    }
    a {
      margin: 0 16px;
      font-size: theme.$font-size-md;
      color: theme.$text1-color;
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      @media (min-width: theme.$breakpoint-lg) {
        justify-content: flex-end;
        flex-direction: row;
      }
    }
  }
}

:local(.containerNavbar) {
  flex-direction: column;
  align-items: center;
  display: none !important;
  @media (min-width: theme.$breakpoint-lg) {
    display: flex !important;
    height: 70px;
    flex-direction: row;
    align-items: center;
    max-width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: theme.$breakpoint-xl) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: theme.$breakpoint-xxl) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: theme.$breakpoint-xxl) {
    padding-left: 120px;
    padding-right: 120px;
  }
}

:local(.container) {
  flex-direction: column;
  align-items: center;
  display: none !important;
  @media (min-width: theme.$breakpoint-lg) {
    display: flex !important;
    height: 70px;
    flex-direction: row;
    align-items: inherit;
  }
  @media (min-width: theme.$breakpoint-xl) {
    max-width: 1100px !important;
  }
  @media (min-width: theme.$breakpoint-xxl) {
    max-width: 1280px !important;
  }
  @media (min-width: theme.$breakpoint-xxl) {
    max-width: 1440px !important;
  }
}

:local(.home-link) {
  display: inline-block;
  width: auto;
  height: 45px;
  @media (min-width: theme.$breakpoint-lg) {
    img {
      height: 32px;
    }
  }
}

:local(.sign-in) {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: theme.$font-size-md;
  font-weight: theme.$font-weight-bold;

  @media (min-width: theme.$breakpoint-lg) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    padding-top: 0;
  }
}

:local(.item) {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: theme.$font-size-md;
  font-weight: theme.$font-weight-bold;
  a {
    margin-left: 8px;
  }

  li {
    display: flex;
    margin: 0px 20px;
    padding: 0;
    list-style: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media (min-width: theme.$breakpoint-lg) {
      justify-content: flex-end;
      flex-direction: row;
    }
  }
  @media (min-width: theme.$breakpoint-lg) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0;
  }
}

:local(.nav-container) {
  position: relative;
  margin-bottom: -66px;
  background-color: theme.$background1-color;
  margin-top: 1rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 0px 23px;
  justify-content: space-between;
  width: 100%;
  @media (min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.mobile-nav-wrapper) {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  header {
    width: 100%;
    height: 220px;
    padding: 0;
  }
  svg {
    cursor: pointer;
    color: theme.$text1-color;
  }
  li:first-child {
    padding-top: 0;
  }
  a {
    font-size: 1.2rem;
  }
  header {
    display: none;
    opacity: 0;
    height: 0px;
    transition: height 0.3s;
    overflow: hidden;
  }
  header.is-active {
    background-color: theme.$background1-color;
    opacity: 1;
    display: flex;
    height: 280px;
    justify-content: center;
    margin-top: 60px;
    visibility: visible;
    transition: opacity 0.4s, visibility 0.4s, height 0.6s;
    ul {
      height: 230px;
      justify-content: space-between;
    }
    a {
      font-size: 14px;
    }
  }
  header.cloud {
    height: fit-content;
    ul {
      height: 200px;
      justify-content: center;
    }
  }
}

:local(.HaveARoomCodeButton) {
  height: 100%;
  display: flex;
  align-items: center;
}
